import React from 'react'
import CardItem from './CardItem'
import "./product.css"

// import data from "./data"
function Services(props) {
   const {data, type}=props
    

    return (
        <div className="main container my-3">

            <div className="heading ">

                <h1 className='text-center mb-3'>{type}</h1>

                {/* <hr/> */}
            </div>
            
                
                <div className="row container-flud "  style={{textDecoration: 'none'}}>

                    {
                        data.map((cardData) => (
                            <CardItem key={cardData.id} cardData={cardData} />

                        ))
                    }
                </div>
                
            </div>
       

    )
}

export default Services