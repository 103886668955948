import Imagess from "./quality.jpg";
import "./quality.css";
import Card from 'react-bootstrap/Card';

const Contact = () => {
    return (
        <>

            <section className="contactus-section">
                <div className="container">
                    <div className="heading "><h1 className='text-center'>Our Quality </h1></div>
                    {/* <hr /> */}
                    <div className="col-12 col-lg-12 mx-auto">
                        <div className="row  mission">
                            <div className="contact-leftside col-12 col-lg-5">

                                <figure>
                                    <img
                                        src={Imagess}
                                        alt="contatUsImg"
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>

                            {/* left side contact form  */}
                            <div className="contact-rightside col-12 col-lg-7">
                               
                                <Card.Text className='fw-normal ' style={{ padding: "10px", textAlign: "justify" }}>
                                    At Gawgee Brothers get the finest quality attire as we solely indulge in manufacturing fashion and
                                    lifestyle clothing products. We are committed to creating distinctive, cutting-edge clothing for people
                                    that enhances their identities. At Garwee Brothers, you may obtain top-notch services at the most
                                    competitive costs, and we're committed to providing the goods that satisfy our consumers. Although we
                                    have a small team and a humble beginning, we are driven and work hard to provide the best and
                                    trendiest clothing at a competitive price. Stop Thinking And Get the Best of Yourself! Excellent
                                    Design Homegrown Superior Quality Distinctive Style Gawgee
                                    Brothers – Get Everything in one location!
                                </Card.Text>
                            </div>
                            {/* right side  */}

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;