import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";


function CategoreyCard(props) {
  const { cardData } = props
  return (

    <Card className='cardItem categorey' style={{ width: '17rem', margin: "10px", boxShadow: "grey -3px 5px 5px" }}>
      <Link to={`/${cardData.name}`} style={{ textDecoration: "none", color: "black" }}>
        <Card.Img style={{ height: '180px' }} variant="top" src={cardData.image} />
        <Card.Body>
          <Card.Title>{cardData.name}</Card.Title>
          <Card.Text>
            {cardData.text}
          </Card.Text>

        </Card.Body>
      </Link>
    </Card>
  );
}
export default CategoreyCard
