

const CategoryData=[
    {   id:1,
        name:"Men",
        image:"https://img.freepik.com/free-photo/portrait-handsome-smiling-young-man-model-wearing-casual-summer-pink-clothes-fashion-stylish-man-posing-round-sunglasses_158538-5346.jpg?w=826&t=st=1662821667~exp=1662822267~hmac=537d807d6e483dc09cbde2efcb50a6810c2102a2b8dc2bf90199597591c2aa0b",
        text:"Comfortable, durable, and stylish trendy clothes for men. From formal shirts to casual pants, auxiliary accessories, and many more. Get everything within a click away.                        "
    },
    {   id:2,
        name:"Women",
        image:"https://img.freepik.com/free-photo/perky-girl-stylish-glasses-stares-amazement-walking-pink-wall-brunette-culottes-orange-blouse-posing-with-red-handbag_197531-14254.jpg?w=900&t=st=1662823155~exp=1662823755~hmac=e84054fe590e259b32cee51b32c90239e4fa17947a6df445a4427994a694ea50",
        text:"Grab chic apparel womens’ collection. Get trendy and fashionable shorts, long dresses,  etc. Also, match your dress code with fashionable jewellery and unlock the queen version of you."
    },
    {   id:3,
        name:"Kids",
        image:"https://img.freepik.com/free-photo/pretty-joyful-young-girl-tulle-skirt-with-long-brunette-hair-walking-with-white-packages-pink-background-lovely-sweet-moments-little-princess-pretty-friendly-child-having-fun-camera_197531-3538.jpg?w=900&t=st=1662823216~exp=1662823816~hmac=bcc5ef2b339a29b607fa831b646078345104c8d84b4d7c79b7dbc45bbab69248",
        text:"Shimmer the brightest and flaunt with fab clothes. Add alternatives like trousers, skirts, denim, dungaree, t-shirts, casual pants shorts, shirts etc, to your child’s look.                                                                  "
        
    },
    // {   id:4,
    //     name:"Baby",
    //     image:"https://img.freepik.com/premium-photo/baby-after-bathing-towel_73944-16776.jpg?w=900"
        
    // }
]

export default CategoryData;