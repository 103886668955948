import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row footer">
                <div className="col-6 col-lg-3">
                  <h2>About Us</h2>
                  <ul>
                    <li>
                      We are on a mission to become a trustworthy wholesaler with a wide base of clients.
                      Gawgee Brothers aim to serve millions and billions of buyers and suppliers globally with
                      excellent quality clothes, leather garments, fashion jewellery and much more.
                    </li>
                  </ul>
                </div>

                <div className="col-6 col-lg-3">
                  <h2>Products</h2>
                  <ul>

                    <Link to="Formals" style={{ textDecoration: "none", color: "black" }}>
                      <li className="product-item">
                        Formal Wear
                      </li></Link>
                    <Link to="Casuals" style={{ textDecoration: "none", color: "black" }}>
                      <li className="product-item">
                        Casual Wear
                      </li></Link>
                    <Link to="Denim" style={{ textDecoration: "none", color: "black" }}>
                      <li className="product-item">
                        Denim Wear
                      </li></Link>
                    <Link to="Jewellery & Accessories" style={{ textDecoration: "none", color: "black" }}>
                      <li className="product-item">
                        Jewellery & Accessories</li>
                    </Link>
                    <Link to="Ethnic" style={{ textDecoration: "none", color: "black" }}>
                      <li className="product-item">
                        Ethnic Wear</li>
                    </Link>
                    <Link to="Leather" style={{ textDecoration: "none", color: "black" }}>

                      <li className="product-item">
                        Leather Garments</li></Link>
                  </ul>
                </div>

                <div className="col-6 col-lg-3">
                  <h2>Contact Us</h2>
                  <ul>
                    <li style={{ color: "#182334" }}>
                      .
                    </li>
                    <li style={{ color: "#182334" }}>
                      .
                    </li>
                    <li style={{ color: "#182334" }}>
                      .
                    </li>
                    <li>
                      Email: info@gawgeebrothers.ae
                    </li>
                    <li>
                    Warehouse 18, Al Khazan Building - Nad Al Hamar Rd - Umm Ramool - Dubai - United Arab Emirates
                    </li>
                    {/* <li>
                    WhatsApp : +91 9638106876
                    </li> */}
                  </ul>
                </div>

                <div className="col-6 col-lg-3">
                  <h2>Follow Us</h2>
                  <div className="row">
                    <div className="col-3 mx-auto">
                      <i className="fab fa-facebook-f fontawesome-style"></i>
                    </div>
                    <div className="col-3 mx-auto">
                      <a
                        href="/"
                        target="">
                        <i className="fab fa-instagram fontawesome-style"></i>
                      </a>
                    </div>
                    <div className="col-3 mx-auto">
                      <i className="fab fa-youtube fontawesome-style"></i>
                    </div>
                    <div className="col-3 mx-auto">
                      <i className="fab fa-twitter fontawesome-style"></i>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mt-3">
                <p className="main-hero-para text-center w-100">
                  Copyright @ 2022 GAWGEE BROTHERS WHOLESALERS CO. LLC All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;