import Carousel from 'react-bootstrap/Carousel';
import "./slider.css"
import first from "./image/1.jpg"
import second from "./image/2.jpg"
import third from "./image/3.jpg"
import fourth from "./image/4.jpg"
function Slider() {
  return (
    <Carousel style={{overflow: "hidden" }} >
      <Carousel.Item interval={1000}>
        <img
          style={{margin:"0px",padding:"0px"}}
          className="d-block w-100 img-fluid img-thumbnail slider"
          src={first}
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img
          style={{margin:"0px",padding:"0px"}}
          className="d-block w-100 img-fluid img-thumbnail  slider"
          src={second}
          alt="Second slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{margin:"0px",padding:"0px"}}
          className="d-block w-100 img-fluid img-thumbnail slider"
          src={third}
          alt="Third slide"
        />

      </Carousel.Item>

      <Carousel.Item>
        <img
          style={{margin:"0px",padding:"0px"}}
          className="d-block w-100 img-fluid img-thumbnail slider"
          src={fourth}
          alt="fourth slide"
        />
      </Carousel.Item>
      </Carousel>
  );
}

export default Slider;