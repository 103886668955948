

const data= [
    
{
    product:[   
        {
            id:"1",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/formalShirt.jpg",
            title:"Formals",
            text:"Elevate formals elegance with an exclusive collection of formals wear.Shirts trousers, pants, dresses, etc."
        },
        {
            id:"2",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/casualWear.jpg",
            title:"Casuals",
            text:"Countless trendy T-shirts, tops, lowers, inner wears, polo shirts, etc for your endless dashing casual look  "
        },
        {
            id:"3",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/denimwear2.jpg",
            title:"Denim",
            text:"Denimize the world with fashionable denim. Grab Jeans, denim shirts, denim jackets, ladies' tops, etc. "
        },
        {
            id:"4",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/indian-jewellery.jpg",
            title:"Jewellery & Accessories",
            text:" Unleash the Diva with deluxe fashion jewellery and accessories such as earrings, rings, neckpieces, etc.    "
        },
    ],
    

formal:[   
    {
        id:"1",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/formalShirt.jpg",
        title:"Shirt",
    },
    {
        id:"2",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/formalPant.jpg",
        title:"Pants",
    },
    {
        id:"3",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/formalSuit.jpg",
        title:"Formal Suit",
    },
    {
        id:"4",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/formaldress.jpg",
        title:"Dresses",
    },
],

Casuals:[
    {
        id:"1",
        image:"https://img.freepik.com/free-psd/black-t-shirt-mockup_125540-430.jpg?w=996&t=st=1662822480~exp=1662823080~hmac=06136818c36cffffc4c7ba8f84c3b6edf788c9a624c78b69dc949b035ab7b317",
        title:"T-shirts",
    },
    {
        id:"2",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/casuadress.jpg",
        title:"Women",
    },
    {
        id:"3",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/casualwear2.jpg",
        title:"Casual Trousers",
    },
    {
        id:"4",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/poloTshirt.jpg",
        title:"Polo Shirts",
    },
],

Denim:[
    {
        id:"1",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/jeans2.jpg",
        title:"Jeans",
    },
    {
        id:"2",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/denimShirt.jpg",
        title:"Denim Shirt",
    },
    {
        id:"3",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/denimJacket.jpg",
        title:"Denim Jackets",
    },
    {
        id:"4",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/denimTop.jpg",
        title:"Denim Tops",
    },
],

EthnicWear:[
    {
        id:"1",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/kurta.jpg",
        title:"Kurta",
    },
    {
        id:"2",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/lehanga.jpg",
        title:"Lehenga",
    },
    {
        id:"3",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/suit.jpg",
        title:"Suit",
    },
    {
        id:"4",
        image:"https://gawgeebrothers.ae/gawgeebrothersImage/saree.jpg",
        title:"Saree",
    },
],
LeatherGarments:[
    {
           id:"1",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/latherJacket.jpg",
           title:"Leather Jackets",
       },
       {
           id:"2",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/latherBlazer.jpg",
           title:"Blazers",
       },
       {
           id:"3",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/latherPant.jpg",
           title:"Leather Pants",
       },
       {
           id:"4",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/leratherWaistcoat.jpg",
           title:"Waistcoat",
       },
   ],
   Jewellery:[
    {
           id:"1",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/earring.jpg",
           title:"Earring",
       },
       {
           id:"2",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/ring.jpg",
           title:"Rings",
       },
       {
           id:"3",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/neck.jpg",
           title:"Neckpieces",
       },
       {
           id:"4",
            image:"https://gawgeebrothers.ae/gawgeebrothersImage/bracelet.jpg",
           title:"Bracelets",
       },
   ]
    
}
    
    
]

export default data;