import React from 'react'

import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import "./card.css"

function CardItem(props) {
    const { cardData} = props
    
    return (

        <Card className='cardItem' style={{ width: '17rem', margin: "10px", boxShadow: "grey -3px 5px 5px" }}>
            <Link to={`/${cardData.title}`} style={{ textDecoration: "none" }}>
                <Card.Img style={{ height: '180px' }} variant="top" src={cardData.image} />
                <Card.Body>
                    <Card.Title className=' title'>{cardData.title}</Card.Title>
                    <Card.Text className=' title txt'>
                        {cardData.text}
                    </Card.Text>

                </Card.Body>
            </Link>
        </Card>

    )
}

export default CardItem