import React, { useState } from 'react'
import "./nav.css"
import logo from "./GB-.png"
import { Link } from "react-router-dom";
function Navbar() {
  const [display,setDisplay]=useState("none")
  const displayclick = ()=>{
    if(display==="none"){
      setDisplay("block")
    }
    else{
      setDisplay("none")
    }
  }
  
  return (
    <>
     <nav className="navbar fixed-top nav navbar-expand-lg nevigation bg-light ">
        <div className="container-fluid ">
          <div className="container-fluid d-flex justify-content-between logo ">
            <div>
              <Link className="navbar-brand my-0 " to="/">
              <span className='text-head'>تجار الجملة الأخوة جاوجي</span>
                <img src={logo} alt="GB" className='logo-img' />
              </Link>
            </div>
            <div className='mt-3'>
              <button  onClick={displayclick} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav" style={{display:display,}}>
            <ul className="navbar-nav me-auto  mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/" onClick={displayclick}  >Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/product" onClick={displayclick}>Product</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/about" onClick={displayclick}>About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/contact" onClick={displayclick}>Contact</Link>
              </li>
            </ul> 
            <div className="d-flex" >
              {/* enter right side element  */}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
