import React from 'react'
import Slider from '../slider/Slider';
import Product from '../product/Product';
import About from '../about/About';
import Category from '../categorey/Category';
import Contact from "../Contacts/Contact";
import data from "../product/data";
import Mission from "../mission/Mission";
import Quality from "../quality/Quality";


function Home() {

  const product = data[0].product
  return (
    <>
      <Slider />
      <Product data={product} type="Our Products" aboutText={product.text} />
      <Category />
      <Mission />
      <Quality />
      <About />
      <Contact />
    </>
  )
}

export default Home