import Imagess from "./about.jpg";
import "./about.css";
import Card from 'react-bootstrap/Card';

const Contact = () => {
  return (
    <>

      <section className="contactus-section">
        <div className="container">
          <div className="heading "><h1 className='text-center'>About Us</h1></div>
          {/* <hr /> */}
          <div className="col-12 col-lg-12 mx-auto">
            <div className="row  mission">

              {/* left side contact form  */}
              <div className="contact-rightside col-12 col-lg-7">
               
                <Card.Text className='fw-normal ' style={{ padding: "10px", textAlign: "justify" }}>
                  Welcome to Gawgee Brothers!

                  We are a young manufacturing company with an intent to produce high-quality clothing while adhering to current fashions. We are in the business of manufacturing, importing, and wholesale premium quality clothes, accessories, leather garments, and much more. Gawgee Brothers is a  top new online wholesaling store around the globe, that strives to offer hassle-free services and garments of the highest calibre. We have a wide range of products for each individual– Men, Women, and Kids.
                  Excel in Manufacturing
                  With our premium quality, we will become a global leader in apparel manufacturing. We constantly engage in developing new innovate, minimizing costs, and improving production
                  Indulge in Importing
                  Our main focus is producing quality for our client satisfaction. Hence, we import various fabrics and raw materials all around the globe to deliver the finest output.

                </Card.Text>
              </div>
              {/* right side  */}
              <div className="contact-leftside col-12 col-lg-5">

                <figure>
                  <img
                    src={Imagess}
                    alt="contatUsImg"
                    className="img-fluid"
                  />
                </figure>
              </div>

            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;