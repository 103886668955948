import './App.css';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import Contact from "./component/Contacts/Contact"
import Home from "./component/home/Home"
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import Product from './component/product/Product'
import About from './component/about/About'
// import Category from './component/categorey/Category';
import data from "./component/product/data"
import Mission from "./component/mission/Mission"
import Quality from "./component/quality/Quality"

function App() {
  const formal = data[0].formal;
  const Casuals = data[0].Casuals;
  const Denim = data[0].Denim;
  const EthnicWear = data[0].EthnicWear;
  const LeatherGarments = data[0].LeatherGarments;
  const Jewellery = data[0].Jewellery;
  return (
    <div itemID='main'>
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={
            <>
              <Product data={formal} type="Formal" />
              <Product data={Casuals} type="Casuals" />
              <Product data={Denim} type="Denim" />
              <Product data={EthnicWear} type="Ethnic Wear" />
              <Product data={LeatherGarments} type="Leather Garments" />
              <Product data={Jewellery} type="Fashion Jewellery & Accessories" />
            </>} />
          <Route path="/about" element={<><About /><Mission /><Quality /></>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Formals" element={<Product data={formal} type="Formal" />} />
          <Route path="/Casuals" element={<Product data={Casuals} type="Casuals" />} />
          <Route path="/Denim" element={<Product data={Denim} type="Denim" />} />
          <Route path="/Jewellery & Accessories" element={<Product data={Jewellery} type="Fashion Jewellery & Accessories" />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
