import Imagess from "./mission.jpg";
import "./Mission.css";
import Card from 'react-bootstrap/Card';

const Contact = () => {
  return (
    <>       

      <section className="contactus-section">
        <div className="container">
        <div className="heading "><h1 className='text-center'>Our Mission</h1></div>
            {/* <hr /> */}
            <div className="col-12 col-lg-11 mx-auto">
          <div className="row  mission">
              
                {/* left side contact form  */}
                <div className="contact-rightside col-12 col-lg-7 mission-text">
                 
                   <Card.Text className='fw-normal  ' style={{ padding: "5px", textAlign: "justify" }}>
                   We are on a mission to become a trustworthy wholesaler with a wide base of clients. 
                   Gawgee Brothers aim to serve millions and billions of buyers and suppliers 
                   globally with excellent quality clothes, leather garments, fashion jewellery and much more.
                   </Card.Text>
                </div>
                {/* right side  */}
                <div className="contact-leftside col-12 col-lg-5">
                  
                  <figure>
                    <img
                      src = {Imagess}
                      alt="contatUsImg"
                      className="img-fluid"
                    />
                  </figure>
                </div>

              </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;