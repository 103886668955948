import React from 'react'
import CategoreyCard from './CategoreyCard'
import CategoreyData from './CategoreyData'



function Category() {
    return (
        <div className="container">
            <div className="heading my-3">
                <h1 className='text-center mb-3'>Our Top Category</h1>
                {/* <hr /> */}
            </div>
            <div className="container-flud">

                <div className="row container-flud " >
                    {
                        CategoreyData.map((Data) => (
                            <CategoreyCard key={Data.id} cardData={Data} />
                        ))
                    }
                </div>

            </div>
        </div>

    )
}

export default Category